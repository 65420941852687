import * as React from 'react';

export interface INotFoundProps {}

export function NotFound (props: INotFoundProps) {
  return (
    <div>
      <h1>404 Not found</h1>
    </div>
  );
}
