import React from 'react';

const Profile = () => {
    return (
        <div>
            <h1>profile</h1>
        </div>
    )
}

export default Profile