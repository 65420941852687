import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import './SignIn.scss';
import { Link, useNavigate } from 'react-router-dom';
import Define from '../../../constants/define';

const SignIn: React.FC = () => {
  const navigate = useNavigate()
  const onFinish = (values: any) => {
    try {
      //call api signin to server
      console.log(values)
      localStorage.setItem(Define.ACCESS_TOKEN, "123")
      navigate('/home')
    } catch (error) {
      //handle error
    }
  };

  return (
    <Form
      name="normal_login"
      className="login-form font-sans"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Link to="/auth/forgetpassword"> Forgot password</Link>
     
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
        Or   <Link to="/auth/signup"> register now!</Link>
      </Form.Item>
    </Form>
  );
};

export default SignIn;
