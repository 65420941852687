import * as React from 'react';
import { Layout, Menu } from 'antd';

const { Header } = Layout;

export interface INavBarProps {
}

export function NavBar (props: INavBarProps) {
  return (
    <div>
      <Header className='navbar'>
        {/* <div className="logo">
            <Image src='../../public/logo192.png' />
            <Image src='../../../../public/logo192.png' />
        </div> */}
        <Menu
        //   theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['1']}
          items={new Array(15).fill(null).map((_, index) => {
            const key = index + 1;
            return {
              key,
              label: `nav ${key}`,
            };
          })}
        />
      </Header>

    </div>
  );
}
