import React from 'react';
import { AdminLayout } from './components/Layout';

function App() {
  return (
    <AdminLayout />
  );
}

export default App;
