import * as React from 'react';
import { Navigate, Outlet, RouteProps } from 'react-router-dom';
import Define from '../../constants/define';

export function PrivateRoute (props: RouteProps) {
    //Checking user has logged to system
    const isLoggedIn = Boolean(localStorage.getItem(Define.ACCESS_TOKEN))
    if (!isLoggedIn) return <Navigate to="/auth/signin" />
  return <Outlet  />
}
