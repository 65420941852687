import React from 'react';

const ForgetPassword = () => {
    return (
        <div>
            <h1>forgetPassword</h1>
        </div>
    )
}

export default ForgetPassword