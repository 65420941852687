import * as React from 'react';
import { Layout } from 'antd';

import { Route, Routes } from 'react-router-dom';
import Profile from '../../features/setting/profile/Profile';
import { NavBar, NotFound, PrivateRoute } from '../Common';
import { Content } from 'antd/es/layout/layout';
import SignIn from '../../features/auth/signIn/SignIn';
import SignUp from '../../features/auth/signUp/SignUp';
import ForgetPassword from '../../features/auth/forgetPassword/ForgetPassword';
import Home from '../../features/home/Home';

export interface IAdminLayoutProps {}

export function AdminLayout(props: IAdminLayoutProps) {
  const isLoggedIn = Boolean(localStorage.getItem('access_token'));
  console.log(isLoggedIn);
  return (
    <Layout>
      {isLoggedIn && <NavBar />}
      <Content>
        <Routes>
          <Route path="/auth/signin" element={<SignIn />} />
          <Route path="/auth/signup" element={<SignUp />} />
          <Route path="/auth/forgetpassword" element={<ForgetPassword />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route index path="/home" element={<Home />} />
            <Route path="/setting/profile" element={<Profile />} />
          </Route>
        </Routes>
      </Content>
    </Layout>
  );
}
